<template>
    <section>
      <va-breadcrumbs>
        <va-breadcrumbs-item label="Educação" to="/admin/educacao/escolas" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #0785d3"
            >Planejamento Pedagógico</span
          >
        </va-breadcrumbs-item>
      </va-breadcrumbs>

      <h2 class="titulo-links-escolha">Planejamento Pedagógico</h2>
      <p>
        A tabela a seguir mostra os Planejamentos Pedagógicos <br>
        de cada disciplina em um ensino escolar em uma data específica.
      </p>
      <div class="row">
        <div class="col-md-8"></div>
        <div class="col-md-4"  v-if="situacaoAno">
           <va-button class="mr-2 mb-2" size="small" color="success" @click="registar()" style="float:right;"> {{ $t('Registrar') }}</va-button>
           <va-button class="mr-2 mb-2" size="small" color="success" @click="irProf()" style="float:right;"> {{ $t('Acompanhar os Professores') }}</va-button>
        </div>
      </div>
      <va-input
        v-if="items.length > 5"
        class="flex mb-2 md6"
        placeholder="Filtrar..."
        v-model="filter"
      />
      <va-data-table  striped="true" hoverable="true" animated="true" 
        
        :items="items" 
        :columns="columns"
        :per-page="perPage"
        :filter="filter"
        :current-page="currentPage" > 

        <template #cell(id)="{ source: id }">
          <va-button class="mr-2 mb-2" size="small" @click="visualizarPlanejamento(id)" v-if="situacaoAno" color="success"> {{ $t('Visualizar') }}</va-button>
          <va-button class="mr-2 mb-2" size="small" @click="editarPlanejamento(id)" v-if="situacaoAno"> {{ $t('Editar') }}</va-button>
          <va-button class="mr-2 mb-2" size="small" @click="deletarPlanejamento(id)" v-if="situacaoAno"> {{ $t('Deletar') }}</va-button>
        </template>

        <template #bodyAppend>
          <tr><td colspan="8" class="table-example--pagination">
            <va-pagination
              v-model="currentPage"
              input
              :pages="pages"
            />
          </td></tr>
        </template>
      </va-data-table> 
    </section>  
</template>

<script>
import { defineComponent } from 'vue'
import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";
import { SegmentoEscolar } from "@/class/segmentoEscolar";
import { SeriesEscolar } from "@/class/serie";
import { DisciplinaEscolar } from "@/class/disciplina";

export default defineComponent({
  data () {
    const columns = [
      {key: 'segmento', label:'Etapa', sortable: true },
      {key: 'serie', label:'Período', sortable: true },
      {key: 'disciplina', label:'Disciplina', sortable: true },
      {key: 'ano', label:'Ano', sortable: true },
      { key: 'id', label:'Ação'},

    ]
    return {
      situacaoAno:1,
      filter: '',
      items: [],
      columns,
      perPage: 20,
      currentPage: 1,
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.items.length / this.perPage)
          : this.items.length
    },
  },
  methods:{
      registar(){
        this.$router.push({ name: 'planejamentoPedaCad'});
      },
      irProf(){
        this.$router.push({ name: 'planejamentoPedaProf'}); 
      },
      editarPlanejamento(id) {
        this.$router.push({ name: "planejamentoPedaEdit", params: { id: id } });
      },
      visualizarPlanejamento(id){
        this.$router.push({ name: "planejamentoVisu", params: { id: id } });
      },
      async deletarPlanejamento(id) {
       const data = await PlanejamentoPedagogico.remover(id);
       this.listarTudo();

      },
      async listarTudo() {
        try {
            this.items = [];
            let anoSelect = sessionStorage.getItem("anoSelecionado");
            let data = await PlanejamentoPedagogico.buscarPlanejamento(anoSelect);

            //this.items = data.data;
            for (const el of data.data) {
              let novo = {
                id_: el.id,
                id: el.id,
                segmento_id:el.segmento_id,
                segmento: el.segmento,
                serie_id: el.serie_id,
                serie: el.serie,
                disciplina_id: el.disciplina_id,
                disciplina: el.disciplina,
                ano: el.ano,
                mes: el.mes,
                dia: el.dia,
                conteudo: el.conteudo
              };
              this.items.push(novo);
            };
        } catch (e) { 
        }
      }, 
  },

  async beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    await this.listarTudo();
  }
  
})

</script>

<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>

<style>
  .semana{
    background-color: rgb(239 239 239);
    text-align: center;
  }
  .font-semana{
    font-size: 10px;
  }
  .dias{
    text-align: center;
  }
  .dia-marcado{
    background-color: #4182c5 !important;
    color: #fff;
  }


  
</style>

